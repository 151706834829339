"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createAndDownloadTxtFile = exports.makePrefix = exports.makeModifiers = exports.makeClassNames = exports.thinspaceNumber = exports.normalizeModuleName = exports.randomString = exports.formatBytes = exports.bytesOptions = exports.capitalize = exports.urljoin = exports.hashCode = exports.getThresholdColorClass = exports.getColorTypeCreator = exports.generatePin = exports.clamp = exports.pluralize = void 0;

var _urlJoin = _interopRequireDefault(require("url-join"));

var _functions = require("./functions");

var _general = require("../constants/general");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e2) { throw _e2; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e3) { didErr = true; err = _e3; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var pluralize = function pluralize(amount, variants) {
  var variant = 2;

  if (amount % 10 === 1 && amount % 100 !== 11) {
    variant = 0;
  } else if (amount % 10 >= 2 && amount % 10 <= 4 && (amount % 100 < 10 || amount % 100 >= 20)) {
    variant = 1;
  }

  return variants[variant];
};

exports.pluralize = pluralize;

var clamp = function clamp(value) {
  var min = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var max = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 100;

  if (value < min) {
    return min;
  }

  if (value > max) {
    return max;
  }

  return value;
};

exports.clamp = clamp;

var generatePin = function generatePin() {
  var count = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 6;
  var numberArray = [];

  for (var i = 0; i < count; i += 1) {
    numberArray.push((0, _functions.random)(0, 9));
  }

  return numberArray.join('');
};

exports.generatePin = generatePin;

var getColorTypeCreator = function getColorTypeCreator(start, end) {
  var prefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'custom-color';
  return function (i) {
    var colorIdx = (start + 1 + i) % end;
    return "".concat(prefix, "-").concat(colorIdx);
  };
};

exports.getColorTypeCreator = getColorTypeCreator;

var getThresholdColorClass = function getThresholdColorClass(value) {
  var _iterator = _createForOfIteratorHelper(_general.thresholdColorsClass),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var colorData = _step.value;

      var _colorData = _slicedToArray(colorData, 2),
          threshold = _colorData[0],
          className = _colorData[1];

      if (value <= threshold) {
        return className;
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return 'safe';
};

exports.getThresholdColorClass = getThresholdColorClass;

var hashCode = function hashCode(string) {
  var hash = 0;

  if (string.length === 0) {
    return hash;
  }

  for (var i = 0; i < string.length; i += 1) {
    var _char = string.charCodeAt(i);

    hash = (hash << 5) - hash + _char; // eslint-disable-line no-bitwise

    hash &= hash; // eslint-disable-line no-bitwise
  }

  return hash;
};

exports.hashCode = hashCode;

var urljoin = function urljoin() {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  var fnArgs = args.filter(function (v) {
    return v.length > 0 || v !== '/';
  });

  var url = _urlJoin["default"].apply(void 0, _toConsumableArray(fnArgs));

  if (!url.endsWith('/')) {
    return _urlJoin["default"].apply(void 0, _toConsumableArray(fnArgs).concat(['/']));
  }

  return url;
};

exports.urljoin = urljoin;

var capitalize = function capitalize(value) {
  return "".concat(value[0].toUpperCase()).concat(value.slice(1));
};

exports.capitalize = capitalize;
var bytesOptions = {
  unit: 'b',
  // Входные юниты
  output: 'b',
  // Выходные юниты (минимально)
  decimals: 2,
  // Кол-во цифр после запятой
  empty: undefined,
  // Тест для нулевого значения,
  units: ['b', 'kb', 'mb', 'gb', 'tb'],
  texts: ['Б', 'КБ', 'МБ', 'ГБ', 'ТБ'],
  onlyNumber: false
};
exports.bytesOptions = bytesOptions;

var formatBytes = function formatBytes(inputSize, inputOptions) {
  var options = _objectSpread(_objectSpread({}, bytesOptions), inputOptions);

  var size = Math.max(parseFloat(inputSize), 0);
  var index = options.units.indexOf(options.unit.toLowerCase());
  var output = options.units.indexOf(options.output.toLowerCase());

  for (var idx = index; idx < options.units.length; ++idx) {
    // eslint-disable-line no-plusplus
    if (output <= idx && size < 1024) {
      index = idx;
      break;
    }

    size /= 1024;
  }

  if (!size && options.empty !== undefined) {
    return options.empty;
  }

  if (options.decimals != null && index !== 0) {
    size = size.toFixed(options.decimals);
  }

  if (options.onlyNumber) {
    return size;
  }

  return "".concat(size, " ").concat(options.texts[index]);
};

exports.formatBytes = formatBytes;

var randomString = function randomString(length) {
  var alphabet = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var result = ''; // eslint-disable-next-line no-plusplus

  for (var i = length; i > 0; --i) {
    result += alphabet[Math.floor(Math.random() * alphabet.length)];
  }

  return result;
};

exports.randomString = randomString;

var prepareClassArgs = function prepareClassArgs(args) {
  var normalized = null;
  var firstArgs = args[0];

  if (args.length > 1) {
    normalized = args;
  } else if ((0, _functions.isArray)(firstArgs)) {
    normalized = firstArgs;
  } else if ((0, _functions.isString)(firstArgs)) {
    normalized = firstArgs.split(' ');
  } else {
    normalized = [];
  }

  return (0, _functions.uniq)(normalized.map(function (v) {
    return v.trim();
  }).filter(function (v) {
    return v && v.length > 0;
  }));
};

var normalizeModuleName = function normalizeModuleName(moduleName) {
  return moduleName.split('/').map(function (item, i) {
    if (i === 0) {
      return item;
    }

    return capitalize(item);
  }).join('');
};

exports.normalizeModuleName = normalizeModuleName;

var thinspaceNumber = function thinspaceNumber(number) {
  var thinspaceAll = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  if (!thinspaceAll && Math.abs(number) < 10000) {
    return number;
  }

  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, String.fromCharCode(0x2009));
};

exports.thinspaceNumber = thinspaceNumber;

var makeClassNames = function makeClassNames() {
  for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    args[_key2] = arguments[_key2];
  }

  return prepareClassArgs(args).join(' ');
};

exports.makeClassNames = makeClassNames;

var makeModifiers = function makeModifiers(prefix) {
  for (var _len3 = arguments.length, args = new Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
    args[_key3 - 1] = arguments[_key3];
  }

  return prepareClassArgs(args).map(function (v) {
    return "".concat(prefix, "--").concat(v);
  }).join(' ');
};

exports.makeModifiers = makeModifiers;

var makePrefix = function makePrefix(prefix) {
  for (var _len4 = arguments.length, args = new Array(_len4 > 1 ? _len4 - 1 : 0), _key4 = 1; _key4 < _len4; _key4++) {
    args[_key4 - 1] = arguments[_key4];
  }

  return prepareClassArgs(args).map(function (v) {
    return "".concat(prefix, "-").concat(v);
  }).join(' ');
};

exports.makePrefix = makePrefix;

var createAndDownloadTxtFile = function createAndDownloadTxtFile(text, fileName, callback) {
  var element = document.createElement('a');
  var file = new Blob([text], {
    type: 'text/plain'
  });
  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element); // Required for this to work in FireFox

  element.click();

  if ((0, _functions.isFunction)(callback)) {
    callback();
  }
};

exports.createAndDownloadTxtFile = createAndDownloadTxtFile;