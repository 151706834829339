"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getChannel = void 0;

var getChannel = function getChannel(channel, user, isPrivate) {
  var channelName = "".concat(isPrivate ? '$' : '').concat(channel);

  if (user) {
    return "".concat(channelName, "#").concat(user);
  }

  return channelName;
};

exports.getChannel = getChannel;