"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.makeWebsocketManager = void 0;

var _isomorphicFetch = _interopRequireDefault(require("isomorphic-fetch"));

var _CentrifugeManager = _interopRequireDefault(require("./CentrifugeManager"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var ws = null;

var makeWebsocketManager = function makeWebsocketManager(url, tokenUrl) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var wsInternal = ws;

  if (window) {
    // eslint-disable-next-line no-underscore-dangle
    wsInternal = window.__wsInternal;
  }

  if (wsInternal) {
    return wsInternal;
  }

  wsInternal = new _CentrifugeManager["default"]({
    channelName: options.channelName
  });
  (0, _isomorphicFetch["default"])(tokenUrl, {
    method: options.method || 'GET',
    headers: options.headers || {},
    credentials: options.credentials || 'same-origin'
  }).then(function (response) {
    return response.json();
  }).then(function (_ref) {
    var token = _ref.token,
        identifier = _ref.identifier;
    wsInternal.configure({
      url: url,
      token: token,
      identifier: identifier
    }, {
      subscribeHeaders: options.subscribeHeaders || {},
      subscribeEndpoint: options.subscribeEndpoint
    });
  });

  if (window) {
    // eslint-disable-next-line no-underscore-dangle
    window.__wsInternal = wsInternal;
  } else {
    ws = wsInternal;
  }

  return wsInternal;
};

exports.makeWebsocketManager = makeWebsocketManager;