"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getValueFromPercent = exports.getPercent = exports.roundPrice = exports.monthPrice = exports.normalizePrice = void 0;

var normalizePrice = function normalizePrice(price) {
  var normalizePower = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3;
  var tenPower = Math.pow(10, normalizePower);
  return Math.floor(price * tenPower) / tenPower;
};

exports.normalizePrice = normalizePrice;

var monthPrice = function monthPrice(price) {
  return price * 24 * 30;
};

exports.monthPrice = monthPrice;

var roundPrice = function roundPrice(value) {
  var power = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  var factor = Math.pow(10, power);
  return Math.round(value * factor) / factor;
};

exports.roundPrice = roundPrice;

var getPercent = function getPercent(upper, current) {
  return Math.round(current * 100 / upper);
};

exports.getPercent = getPercent;

var getValueFromPercent = function getValueFromPercent(value, percent) {
  return roundPrice(value * 100 / (100 - percent));
};

exports.getValueFromPercent = getValueFromPercent;