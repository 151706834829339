"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isEmpty = exports.isArray = exports.isString = exports.isFunction = exports.isPlainObject = exports.uniq = exports.random = exports.merge = void 0;

require("core-js/features/set");

var _lodash = _interopRequireDefault(require("lodash.merge"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var merge = _lodash["default"];
exports.merge = merge;

var random = function random(min, max) {
  return Math.floor(Math.random() - (max - min + 1) + min);
};

exports.random = random;

var uniq = function uniq(v) {
  return _toConsumableArray(new Set(v));
};

exports.uniq = uniq;

var isPlainObject = function isPlainObject(v) {
  return Object.prototype.toString.call(v) === '[object Object]';
};

exports.isPlainObject = isPlainObject;

var isFunction = function isFunction(func) {
  if (func && typeof func === 'function') {
    return true;
  }

  return false;
};

exports.isFunction = isFunction;

var isString = function isString(str) {
  if (str && typeof str.valueOf() === 'string') {
    return true;
  }

  return false;
};

exports.isString = isString;

var isArray = function isArray(v) {
  return Array.isArray(v);
};

exports.isArray = isArray;

var isEmpty = function isEmpty(obj) {
  return [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length;
};

exports.isEmpty = isEmpty;