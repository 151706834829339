"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mbToGb = exports.bToMb = void 0;

var bToMb = function bToMb(value) {
  var isRound = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var rawValue = value / 1024 / 1024;

  if (isRound === false) {
    return rawValue;
  }

  return Math.round(rawValue);
};

exports.bToMb = bToMb;

var mbToGb = function mbToGb(value) {
  var isRound = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var rawValue = value / 1024;

  if (isRound === false) {
    return rawValue;
  }

  return Math.round(rawValue);
};

exports.mbToGb = mbToGb;