"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.statisticIntervals = exports.statsServerColorsClassMap = exports.thresholdColorsClass = void 0;
var thresholdColorsClass = [[55, 'safe'], [80, 'warning'], [100, 'danger']];
exports.thresholdColorsClass = thresholdColorsClass;
var statsServerColorsClassMap = {
  OK: 'safe',
  Warning: 'warning',
  Critical: 'danger'
};
exports.statsServerColorsClassMap = statsServerColorsClassMap;
var statisticIntervals = [{
  title: 'День',
  type: 'day',
  value: 1
}, {
  title: 'Неделя',
  type: 'week',
  value: 7
}, {
  title: 'Месяц',
  type: 'month',
  value: 30
}];
exports.statisticIntervals = statisticIntervals;